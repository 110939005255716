import * as React from "react"

class News extends React.Component {

    render() {
        return (
            <section className="py-8 lg:py-12" id="news">
                <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4 lg:px-64 lg:text-center">News from blog</h2>
                    <p className="text-gray-500 font-normal text-lg lg:text-xl mb-10 lg:mb-20 lg:px-56 lg:text-center">Check out the latest blog posts about Bergify</p>
                    
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm">
                            <div className="p-5">
                                <a href="https://blog.bergify.com/mastering-website-wireframe-in-wordpress/" target="_blank">
                                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight my-3">Mastering Website Wireframe in WordPress: Harnessing Bergify Grayscale Patterns</h5>
                                </a>
                                <p className="font-normal text-gray-700 mb-3">This article delves into an innovative approach to creating a wireframe in WordPress, spotlighting the Bergify theme’s unique grayscale global style, meticulously crafted to expedite and enhance the wireframing process.</p>
                                <a href="https://blog.bergify.com/mastering-website-wireframe-in-wordpress/" target="_blank" className="text-purple-600 lg:-text-lg font-medium py-2 text-center inline-flex items-center">
                                    Read more
                                    <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm">
                            <div className="p-5">
                                <a href="https://blog.bergify.com/three-key-elements-of-wordpress-block-theme/" target="_blank">
                                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight my-3">Choose the Right Theme: Three Key Elements of a WordPress Block Theme</h5>
                                </a>
                                <p className="font-normal text-gray-700 mb-3">The choice of a WordPress theme can make or break the success of your online presence. With the rise of WordPress Block themes, the game has been taken to a whole new level. These themes represent a paradigm shift when compared to the previous classic themes.</p>
                                <a href="https://blog.bergify.com/three-key-elements-of-wordpress-block-theme/" target="_blank" className="text-purple-600 lg:-text-lg font-medium py-2 text-center inline-flex items-center">
                                    Read more
                                    <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm">
                            {/* <a href="#">
                                <img className="rounded-t-lg" src="https://flowbite.com/docs/images/blog/image-1.jpg" alt="" />
                            </a> */}
                            <div className="p-5">
                                <a href="https://blog.bergify.com/the-power-of-block-patterns-in-wordpress/" target="_blank">
                                    <h5 className="text-gray-900 font-bold text-2xl tracking-tight my-3">The Power of Block Patterns in WordPress</h5>
                                </a>
                                <p className="font-normal text-gray-700 mb-3">With the introduction of the new block editor (Gutenberg), WordPress took a significant leap forward in terms of flexibility and creativity. Among the exciting features that the new editor offers, block patterns in WordPress have emerged as a game-changer.</p>
                                <a href="https://blog.bergify.com/the-power-of-block-patterns-in-wordpress/" target="_blank" className="text-purple-600 lg:-text-lg font-medium py-2 text-center inline-flex items-center">
                                    Read more
                                    <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default News
