import * as React from "react"
import components from '../../data/components';

// markup
const Components = () => {
    return (
        <section className="my-8 py-16 bg-gray-50" id="templates">
            <div className="max-w-screen-xl px-4 lg:px-12 mx-auto lg:text-center">
                <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 lg:text-center 2xl:px-48 mb-4">The new editing experience</h2>
                <p className="text-gray-500 font-normal lg:text-center text-lg lg:text-xl xl:px-32 mb-10 lg:mb-16">Say goodbye to a blank canvas when creating a new page. With our full-page templates, you can quickly insert pre-designed layouts for your homepage, about page, contact page, and more. In the editor you can customize every aspect of the page to match your brand and style.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    {components.map(c => (
                        <a href={c.link} target="_blank" className="rounded-xl transition border border-gray-100 hover:border-white hover:shadow-lg bg-white">
                            <div className="rounded-t-lg py-2.5 px-5 flex justify-between items-center border-b border-gray-200">
                                <span className="font-medium text-gray-500 text-lg">{c.name}</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                            </div>
                            <div className="flex justify-center items-center">
                                <img className={c.class} src={c.png} alt={c.name} />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Components
