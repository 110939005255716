import homePNG from '../images/full-pages/home.png';
import servicePNG from '../images/full-pages/services.png';
import aboutPNG from '../images/full-pages/about.png';
import contactPNG from '../images/full-pages/contact.png';

export default [
    {
        key: 1,
        name: "Homepage",
        png: homePNG,
        link: "https://demo.bergify.com/",
        class: "w-full rounded-lg"
    },
    {
        key: 2,
        name: "Services",
        png: servicePNG,
        link: "https://demo.bergify.com/services/",
        class: "w-full rounded-lg"
    },
    {
        key: 3,
        name: "About us",
        png: aboutPNG,
        link: "https://demo.bergify.com/about-us/",
        class: "w-full rounded-lg"
    },
    {
        key: 4,
        name: "Contact",
        png: contactPNG,
        link: "https://demo.bergify.com/contact/",
        class: "w-full rounded-lg"
    }
]