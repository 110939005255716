import * as React from "react"
import { Link } from "gatsby"
import ImageCarousel from "../../data/image-carousel"

class ProVersion extends React.Component {

    render() {
        return (

            <section className="py-10 lg:py-20 bg-gray-50" id="pro">
            <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 xl:gap-8 items-center">
                    <div className="mb-5 lg:mb-0 lg:col-span-2 order-2 lg:order-1">
                        <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4">Take your website to the next level with the PRO version</h2>
                        <p className="text-gray-500 font-normal text-lg lg:text-xl mb-6 lg:mb-8">Bergify PRO goes beyond just offering more templates - it provides a complete solution for designing your website. It's flexible, versatile, and complex, yet still easy to use and efficient.</p>
                        <div className="lg:flex items-center">
                            <Link to="/pro/" target="_blank" className="block w-full sm:w-auto sm:inline-block mb-3 sm:mb-0 text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-base px-6 py-2.5 text-center inline-block mr-4">Explore Bergify Pro</Link>
                            <Link to="/pro/#pricing" className="block w-full sm:w-auto sm:inline-block text-gray-600 bg-white hover:bg-gray-100 hover:text-blue-600 border border-gray-200 font-semibold rounded-xl text-base px-6 py-2.5 text-center inline-flex justify-center items-center">Pricing & FAQ</Link>
                        </div>
                    </div>
                    <div id="laptop-carousel" className="order-1 lg:order-2 mb-5 lg:col-span-3">
                        <div><ImageCarousel /></div>
                    </div>
                </div>
            </div>
        </section>
        )
    }

}

export default ProVersion
