import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";

import ogImage from "./../images/og-image.jpg";

import Navbar from '../sections/navbar.js';
import Hero from '../sections/homepage/hero.js';
import Components from '../sections/homepage/components.js';
import Features from '../sections/homepage/features';
import ProVersion from '../sections/homepage/pro-version.js';
import News from '../sections/homepage/news.js';
import Footer from '../sections/footer.js';

const IndexPage = () => {
  return (
    <div id="overview">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bergify - Wordpress Theme</title>
        <link rel="canonical" href="https://www.bergify.com" />
        <meta name="description" content="Bergify is a block-based Wordpress theme suitable for businesses." />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@bergify_" />
        <meta name="twitter:title" content="Bergify - Wordpress Theme" />
        <meta name="twitter:description" content="Bergify is a block-based Wordpress theme suitable for businesses." />
        <meta name="twitter:image" content={'https://bergify.com' + ogImage} />
        <meta name="twitter:creator" content="@bergify_" />

        <meta property="og:url" content="https://www.bergify.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Bergify - Wordpress Theme" />
        <meta property="og:description" content="Bergify is a block-based Wordpress theme suitable for businesses." />
        <meta property="og:image" content={'https://www.bergify.com' + ogImage} />
      </Helmet>

      <Navbar />

      <main>
        <Hero />
        {/* <FeaturedIn /> */}
        <Components />
        <Features />
        <ProVersion />
        <News />
        {/* <FAQ /> */}
        {/* <Subscribe /> */}
        <Footer />
      </main>
    </div >
  );
}
export default IndexPage