import * as React from "react"
import { Link } from "gatsby"
import flowbiteLogo from '../../images/logo.svg';

class Features extends React.Component {

    render() {
        return (
            <section className="py-8 lg:py-12" id="features">
                <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4 lg:px-64 lg:text-center">Why choose Bergify</h2>
                    <p className="text-gray-500 font-normal text-lg lg:text-xl mb-10 lg:mb-20 lg:px-56 lg:text-center">Not just another Wordpress theme but a complete design system for your Wordpress site.</p>

                    <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                            <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Create your site in a few simple steps</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">Start building your website by choosing a page template, then customize the content, and publish. You can have a stunning website up and running in no time, without any hassle or complexity.</p>
                                </div>
                            </div>
                        </li>
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                            <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Designed for beginners and professionals alike</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">Full-page templates are an excellent starting point for beginners. With just one click, you can create a complete new page. Professionals, on the other hand, will appreciate the pre-designed sections that feature a uniform design and are easy to customize.</p>
                                </div>
                            </div>
                        </li>
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                            <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"></path><path stroke-linecap="round" stroke-linejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008z"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Easy to customize</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">Bergify offers effortless customization options without coding. Customize individual blocks, pages and sections easily. You can update your entire site's design system with global styles, like colors, fonts etc.</p>
                                </div>
                            </div>
                        </li>
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                            <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Works with the default Wordpress editor</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">No website builder is needed. With Bergify you just effortlessly create your website using customizable sections and blocks, whether you're adding text, images, or other advanced elements. It's intuitive and fast.</p>
                                </div>
                            </div>
                        </li>
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                            <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Fast performance, good for SEO</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">Optimized for speed, your pages will load quickly, providing a seamless experience for your visitors. Additionally, faster load times can boost your site's SEO and improve your overall search engine ranking.</p>
                                </div>
                            </div>
                        </li>
                        <li className="lg:mb-3">
                            <div className="flex flex-wrap rounded-lg py-3 lg:p-5">
                                <svg className="text-gray-700 h-8 mb-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path></svg>
                                <div>
                                    <h3 className="text-xl lg:text-2xl font-bold text-gray-900 mb-2">Mobile friendly</h3>
                                    <p className="text-gray-500 lg:text-lg mb-3">With mobile optimization, your website will look great on any device, making it easier for users to access and navigate your content.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }

}

export default Features
